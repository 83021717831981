import React, { Fragment } from "react"
import "./404.scss"
import { graphql, StaticQuery, Link } from "gatsby"
import { Helmet } from "react-helmet"
import GlobalHeader from "../components/GlobalHeader"
import GlobalFooter from "../components/GlobalFooter"

export default () => (
  <StaticQuery
    query={graphql`
      query NotFoundQuery {
        agility404PageContent {
          customFields {
            subheadline
            title
            aboveCTAsText
            leftCTA {
              href
              target
              text
            }
            rightCTA {
              href
              target
              text
            }
          }
        }
      }
    `}
    render={data => {
      const customFields = data.agility404PageContent.customFields
      return <NotFound props={customFields} />
    }}
  />
)

const NotFound = ({ props }) => {
  const backgroundStyles = `
    .not-found-wrapper {
      background-color: #000;
      background-image: ${props.mobileBackgroundImage}
    }

    @media (min-width: 768px) {
      .not-found-wrapper {
        background-image: ${props.backgroundImage}
      }
    }
  `

  return (
    <Fragment>
      <Helmet>
        <title>404 Page Not Found - Pixel506</title>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <GlobalHeader
        languageCode={"en-us"}
        isMultiLanguage={false}
        variation="dark"
        route={{
          pathname: "/404",
          href: typeof window !== `undefined` ? window.location.href : "/",
          origin: typeof window !== `undefined` ? window.location.origin : "/",
        }}
      ></GlobalHeader>
      <style>{backgroundStyles}</style>
      <main>
        <section className="not-found">
          <div className="not-found-wrapper background-cover">
            <div className="container not-found-container">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-center justify-content-md-start align-items-center pt-5 circles-wrapper">
                    <span className="pink-circle">4</span>
                    <span className="pink-circle">0</span>
                    <span className="pink-circle">4</span>
                  </div>
                  <h1 className="title">{props.title}</h1>
                  <p className="subheadline">{props.subheadline}</p>
                  <p className="cta-text">{props.aboveCTAsText}</p>
                  <div className="d-flex justify-content-around justify-content-md-start align-items-center">
                    <Link
                      className="btn btn-primary-pink black mr-md-4"
                      to={props.rightCTA.url}
                    >
                      {props.rightCTA.text}
                    </Link>
                    <Link
                      className="btn btn-secondary-pink"
                      to={props.leftCTA.url}
                    >
                      {props.leftCTA.text}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <GlobalFooter
        languageCode={"en-us"}
        isMultiLanguage={false}
        route={{
          pathname: "/404",
          href: typeof window !== `undefined` ? window.location.href : "/",
          origin: typeof window !== `undefined` ? window.location.origin : "/",
        }}
      ></GlobalFooter>
    </Fragment>
  )
}
